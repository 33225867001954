import React from 'react';
// import { rhythm } from '../utils/typography'
import { Link } from 'gatsby'
import Img from 'gatsby-image';
import styled from 'styled-components'

const Container = styled.div`
    /*border: 1px solid  red;*/
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-column-gap: 1rem;

`;

const ImgBox = styled.div`
height: 200px;
width: 250px;
`;

const PostInfo = styled.div`
display: grid;
grid-template-rows: auto;
`;


export const Card = (data) => {
    
    return (
        <Container>
        <ImgBox>
        <Link style={{ boxShadow: `none` }} to={data.node.fields.slug}>
        <Img  fluid={data.node.frontmatter.cover.childImageSharp.fluid} />
        </Link>
        </ImgBox>

        <PostInfo>
        <Link style={{ boxShadow: `none` }} to={data.node.fields.slug}>
            <large>{data.node.frontmatter.title}</large>
            <p dangerouslySetInnerHTML={{ __html: data.node.excerpt }} />
        </Link>

        </PostInfo>
        </Container>
    )
}
